const SelectInputRegister = ({ register, name, label, options, optDefault, errors, onChange = null}) => {

  const handleError = mensaje => {
    switch (name) {
      case 'specialty':
        return 'La Especialidad es requerida';
        break;
      case 'country':
        return 'El País es requerido';
        break;
      case 'countryState':
        return 'El Estado es requerido';
        break;
      case 'placeWork':
        return 'El Lugar de Trabajo es requerido';
        break;
    }
  }

  return (
    <div>
      <label htmlFor={name} className="text-sm text-gray-600 mb-2 block">{label}</label>

      <select
        id={name}
        name={name}
        onChange={e => (onChange ? onChange(e.target.value) : null)}
        className={`bg-white w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-bi-green-malachite-500 focus:border-bi-green-malachite-500 sm:text-sm h-10`}
        ref={register}
      >
        <option placeholder value=''>{optDefault}</option>

        {
          options &&
          options.map(({ id, name }) => {
            return (
              <option key={id} value={id}>
                {name}
              </option>
            )
          })
        }

      </select>
      {errors[name]?.message && (
        <p className="text-xs text-red-400 pl-5 mt-1">
          {handleError(errors[name]?.message)}
        </p>
      )}
    </div>
  );
};

export default SelectInputRegister;
